<template>
    <div class="CooperationMethod">
        <img src="../assets/hzgx.png" style="width:100%" alt="">
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style scoped>

</style>